<!--
  Component that checks if the token parameter
  if it exists redirect and pass the parameters to the password creation page
-->
<template>
  <CContainer fluid class="login-common password-recovery text-center">
    <CRow class="header">
      <img src="~@/assets/img/logo-nissan-white.png" alt="logo-nissan" />
    </CRow>
    <CRow align-horizontal="center">
      <img src="~@/assets/img/logo-login.png" class="logo" />
    </CRow>
    <CRow align-horizontal="center">
      <CCol>
        <h3 class="title">{{ messages.passwordRecovery.title }}</h3>
        <ErrorMessage
          v-if="errorManager !== null"
          :message="errorManager.message"
          :styleClass="errorManager.styleClass"
          class="error-message error-message--bottom"
        />
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import languages from "@/languages/languages";
import langStr from "@/utils/langStr";

const ErrorMessage = () =>
  import(
    /* webpackChunkName: "error-message" */ "@/components/atoms/ErrorMessage"
  );

export default {
  name: "ResetPassword",
  components: {
    ErrorMessage,
  },
  data() {
    return {
      token: this.$route.query.token,
      langId: this.$route.query.languageId,
      lang: null,
      languages: languages,
      errorManager: null,
    };
  },
  computed: {
    langStr() {
      return langStr.get(this.langId);
    },
    messages() {
      return this.languages.texts(this.langStr);
    },
  },
  created() {
    /**
     * Check the token parameter, if it exists redirect and pass
     * the parameters to the password creation page.
     */
    if (typeof this.token === "undefined" || this.token.trim() === "") {
      this.errorManager = {
        message: `${this.messages.errors["000"]}`,
      };
    } else {
      const paramLang = this.langStr,
        paramToken = this.token;
      this.$router.push({
        name: "PasswordCreation",
        params: {
          lang: paramLang,
          token: paramToken,
        },
      });
    }
  },
};
</script>
